<template>
  <v-container>
    <taxFundList/>
  </v-container>
</template>

<script>

import taxFundList from '../../components/shared/taxFund/taxFundList.vue';

export default {
  name: 'taxFund',
  components: {
    taxFundList,
  },
};

</script>
