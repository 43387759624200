<template>
  <div>
    <v-container >
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :headers="headers"
        :baseCrudKey="baseCrudKey"
        :createNewItem="newItemDialog"
        toolbarTitle="Tax Fund"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsrtTransaction"
        :defaultSortBy="sortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <taxFundEditedItem
            :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import taxFundEditedItem from './taxFundEditedItem.vue';
import {
  amountToNumber,
  extractId,
} from '../../../util/shared/vue-global';
import {
  toFiscalYear,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Fund', value: 'fundNumber' },
    { text: 'Description', value: 'description' },
    { text: 'Mills', value: 'defaultMills' },
    { text: 'To Unit', value: 'taxUnit.toUnitDescForAppro' },
    { text: 'To Page', value: 'taxUnit.appSection' },
  ];
  return headers;
};

export default {
  name: 'taxFundList',
  components: {
    CRUDList,
    taxFundEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedTaxFund',
    headers: [],
    sortBy: [
      {
        column: 'fundNumber',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadLists();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
    ]),
    ...mapGetters(['todaysDate']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    currentFiscalYear() {
      return toFiscalYear(new Date(this.todaysDate));
    },
  },
  methods: {
    ...mapActions('shared/taxFund', [
      'loadTaxFund',
      'upsertTaxFund',
    ]),
    ...mapActions('shared/taxUnit', [
      'loadTaxUnit',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadTaxFund({
        criteria,
        baseCrudKey,
      });
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      updatedItem.defaultMills = amountToNumber(updatedItem.defaultMills);
      if (updatedItem.taxUnit) {
        updatedItem.taxUnit = extractId(updatedItem.taxUnit);
      }
      await this.upsertTaxFund({ item: updatedItem, baseCrudKey });
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fundNumber: '',
        description: '',
        defaultMills: 0,
        taxUnit: null,
      };
      return newItem;
    },
    async loadLists() {
      await this.loadTaxUnit({
        criteria: {
          fiscalYear: this.currentFiscalYear,
        },
        baseCrudKey: 'sharedTaxUnit',
      });
    },
  },
};

</script>
