<template>
   <v-container>
    <v-row>
      <v-col cols="4">
        <v-text-field id="taxFundEditDialogFundNumberText"
          class="tax-fund-edit-dialog-fund-number-text"
          v-model="editedItem.fundNumber"
          label="Fund Number"
          @blur="somethingChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="taxFundEditDialogDescriptionText"
          class="tax-fund-edit-dialog-description-text"
          v-model="editedItem.description"
          label="Description"
          @blur="somethingChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="taxFundEditDialogDefaultMillsText"
          class="tax-fund-edit-dialog-default-mills-text"
          v-model.number="editedItem.defaultMills"
          label="Default Mills"
          @blur="somethingChanged"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select id="taxFundEditDialogToUnitSelect"
          class="tax-fund-edit-dialog-to-unit-select"
          v-model="editedItem.taxUnit"
          label="To Unit"
          :items="toUnitDescList"
          item-text="toUnitDesc"
          item-value="_id"
          @blur="somethingChanged"
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-checkbox id="taxFundEditDialogCalcGrossCheckbox"
          class="tax-fund-edit-dialog-calc-gross-checkbox"
          v-model="editedItem.grossAssessed"
          label="Calc on GROSS Advanced"
          @blur="somethingChanged"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  // mapActions,
} from 'vuex';

export default {
  name: 'taxFundEditedItem',
  components: {
  },
  data: () => ({
    taxUnitCrudKey: 'sharedTaxUnit',
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'taxFund-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    toUnitDescList() {
      const unitDescObj = this.items(this.taxUnitCrudKey) || [];
      const updatedUnitDescObj = unitDescObj.map((u) => ({
        ...u,
        toUnitDesc: `${u.toUnitDescForAppro || ''}  ${u.description || ''}`,
      }));
      return [{ _id: null, toUnitDescForAppro: '', toUnitDesc: '' }, ...updatedUnitDescObj];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
  },
};
</script>
